import { GameType } from "../application";

export interface IOption {
    key: string;
    typeOnly?: string;
    value?: string;
    placeholder?: string;
    options?: string[];
    range?: {
        min: number;
        max: number;
        interval: number;
        default?: number;
    }
}

export const Options: { [key: string]: IOption } = {
    Currency: {
        key: "currency",
        options: [
            "GBP",
            "AMD",
            "ARS",
            "AUD",
            "AZN",
            "BAM",
            "BDT",
            "BGN",
            "BOB",
            "BRL",
            "BTP",
            "BWP",
            "BYN",
            "BZD",
            "CAD",
            "CGC",
            "CHF",
            "CLP",
            "CNY",
            "COP",
            "CRC",
            "CSC",
            "CZK",
            "DKK",
            "EUR",
            "FTN",
            "GEL",
            "GHS",
            "GTQ",
            "GYD",
            "HKD",
            "HNL",
            "HRK",
            "HUF",
            "IDR",
            "IDX",
            "ILS",
            "INR",
            "IRR",
            "ISK",
            "JMD",
            "JPY",
            "KES",
            "KRW",
            "KZT",
            "LKR",
            "MAD",
            "MGA",
            "MKD",
            "MMK",
            "MXN",
            "MYK",
            "MYR",
            "NGN",
            "NIO",
            "NOK",
            "NZD",
            "PEN",
            "PGK",
            "PHP",
            "PKR",
            "PLN",
            "PTS",
            "PYG",
            "RON",
            "RSD",
            "RUB",
            "RWF",
            "SEK",
            "SGD",
            "SKR",
            "SRD",
            "tCAD",
            "tGBP",
            "tEUR",
            "tMXN",
            "tUSD",
            "TGC",
            "THB",
            "TND",
            "TRY",
            "TTD",
            "TWD",
            "TZS",
            "UAH",
            "UGX",
            "USD",
            "UYU",
            "UZS",
            "VES",
            "VND",
            "VNX",
            "VUV",
            "XAF",
            "ZAR",
            "ZMW"
        ]
    },
    Jurisdiction: {
        key: "jurisdiction",
        placeholder: "Not Set",
        options: [
            "",
            "uk",
            "mt",
            "se",
            "it",
            "dk",
            "es",
            "cz",
            "ad",
            "no",
            "ro",
            "lv",
            "br",
            "de",
            "ca-qc",
            "gr",
            "co"
        ]
    },
    Language: {
        key: "lang",
        options: [
            "en-GB",
            "en-CS",
            "en-CG",
            "sv-SE",
            "de-DE",
            "pl-PL",
            "hu-HU",
            "cs-CZ",
            "sk-SK",
            "no-NO",
            "fi-FI",
            "tr-TR",
            "ru-RU",
            "zh-CN",
            "ja-JP",
            "pt-PT",
            "es-ES",
            "da-DK",
            "it-IT",
            "fr-FR",
            "el-GR",
            "hr-HR",
            "ro-RO",
            "ko-KR",
            "th-TH",
            "vi-VN",
            "es-LA",
            "pt-BR",
            "nl-NL",
            "lv-LV",
            "lt-LT",
            "bg-BG"
        ]
    },
    Platform: {
        key: "platform",
        options: [
            "gamevy",
            "everymatrix",
            "nyx-ogs",
            "vgw",
            "bede"
        ]
    },
    Operator: {
        key: "operator",
        options: [
            "gamevy",
            "norsk_tipping",
            "saska",
            "daub",
            "sisal_italy",
            "lotto_quebec",
            "chumba",
            "betvictor"
        ]
    },
    Region: {
        key: "region",
        options: [
            "euwest1",
            "euwest2"
        ]
    },
    "Reality Check Interval": {
        key: "realityCheckInterval",
        range: {
            min: 0,
            max: 600,
            interval: 0.5
        }
    },
    "Reality Check Passed": {
        key: "realityCheckTimePassed",
        range: {
            min: 0,
            max: 600,
            interval: 0.5
        }
    },
    "Lobby URL": {
        key: "lobbyUrl",
        value: "http://www.example.com/"
    },
    "History URL": {
        key: "historyUrl",
        value: "http://www.example.com/"
    },
    "Initial Balance": {
        typeOnly: "slotworks",
        key: "initialBalance",
        value: "2000"
    },
    "Disable Rules Check Error": {
        typeOnly: "slotworks",
        key: "disableRulesCheckError",
        options: [
            "false",
            "true"
        ]
    },
    Offline: {
        typeOnly: "slotworks",
        key: "offline",
        options: [
            "false",
            "true"
        ]
    },
    "Show FPS (5.x only)": {
        typeOnly: "slotworks",
        key: "fps",
        options: [
            "false",
            "true"
        ]
    },
    Extra: {
        typeOnly: "slotworks",
        key: "extra",
        value: "",
        placeholder: "&additionalParameter=value"
    },
    "Client Type": {
        typeOnly: "snowflake",
        key: "clientType",
        options: [
            "desktop",
            "mobile"
        ]
    },
    "Force Behaviour": {
        typeOnly: "snowflake",
        key: "behaviour",
        placeholder: "None",
        options: [
            ""
        ]
    },
    "Ns": {
        typeOnly: "snowflake",
        key: "ns",
        placeholder: "[58,8,23,10,40,1106517]",
        value: ""
    },
    "uncertifiedShuffle": {
        typeOnly: "snowflake",
        key: "uncertifiedShuffle",
        placeholder: "[[false,false,false,true,false,false,false,true,false,false,true,false,false,true,true]]",
        value: ""
    },
    "uncertifiedNs": {
        typeOnly: "snowflake",
        key: "uncertifiedNs",
        placeholder: "[1569]",
        value: ""
    }
};

export const SnowflakeBranches = [ "test", "staging", "prod" ];
export const SnowflakeBranchDefault = "staging";
export const SnowflakeServers = [ "eu", "gib", "hkg", "apeast1", "cacentral1", "eucentral1", "euwest1", "euwest2", "useast1" ];
export const SnowflakeServerDefault = "euwest1";