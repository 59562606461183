import React from "react";
import { DropdownItemProps, Form, Grid, Segment, Button, Divider } from "semantic-ui-react";
import { GameType } from "../application";
import { IOption, Options } from "../data/options";
import { IsMobile } from "../utils/contextual-css";
import { TabComponent } from "./tab-component";

interface IProperties {
    client: string;
    branch: string;
    username: string;
    gameType: GameType;
    rtp: string[];
    forces: string[];

    onParamsChange: (params: { [key: string]: string }) => void;
}

interface IState {
    params: { [key: string]: string },
    options: { [key: string]: IOption },
    storedBranch: string,
    gameType: GameType,
    lastUsername: string
}

export class GameOptions extends TabComponent<IProperties, IState> {
    constructor(props: IProperties) {
        super(props, "GameOptions");

        this.state = {
            params: this.setDefaultParams(),
            options: Options,
            storedBranch: "",
            gameType: GameType.Slotworks,
            lastUsername: ""
        };

        this.props.onParamsChange(this.state.params);
    }

    public componentDidMount(): void {
        super.componentDidMount();
    }

    public componentDidUpdate(): void {
        if (this.props.branch !== this.state.storedBranch) {
            this.setState({ storedBranch: this.props.branch }, () => {
                const production = this.props.branch === "master" || this.props.branch === "demo";
                if (this.state.options["Disable Rules Check Error"].typeOnly === this.props.gameType) {
                    this.onOptionUpdate("disableRulesCheckError", (production) ? "false" : "true");
                }
            });
        }

        if (this.props.gameType !== this.state.gameType) {
            this.setState({ gameType: this.props.gameType }, () => {
                this.resetParams();
            });
        }

        if (this.state.params["behaviour"]) {
            if (this.props.forces.indexOf(this.state.params["behaviour"]) === -1 || (this.props.username !== this.state.lastUsername && this.props.username.length > 0)) {
                const params = this.state.params;
                params["behaviour"] = "";
                this.setState({ params, lastUsername: this.props.username }, () => {
                    this.onOptionUpdate("behaviour", "");
                });
            }
        } else if (this.props.username !== this.state.lastUsername && this.props.username.length > 0) {
            this.setState({ lastUsername: this.props.username });
        }
        
        super.componentDidUpdate();
    }

    protected onStateRestored() {
        this.props.onParamsChange(this.state.params);
    }

    public render(): JSX.Element {
        const options: Array<JSX.Element | undefined> = [];

        for (const key in this.state.options) {
            if (this.state.options[key].typeOnly === undefined || this.state.options[key].typeOnly === this.props.gameType) {
                options.push(this.getOption(key, this.state.options[key]));
            }
        }

        return (
            <Segment>
                <Form>
                    <Grid columns={(IsMobile() ? 1 : 4)}>
                        { options }
                    </Grid>
                </Form>
                <Divider hidden />
                <Button content="Reset Parameters" fluid secondary onClick={() => this.resetParams()} />
            </Segment>
        )
    }

    private resetParams(): void {
        this.setState({ params: this.setDefaultParams(), options: Options }, () => {
            this.props.onParamsChange(this.state.params);
        });
    }

    private setDefaultParams(): { [key: string]: string } {
        const params: { [key: string]: string } = {};

        params["env"] = "staging";

        const opts = Options as { [key: string]: IOption };
        for (const key in opts) {
            const entry = opts[key];
            if (entry.value !== undefined) {
                params[entry.key] = entry.value;
            } else if (entry.options !== undefined) {
                params[entry.key] = entry.options[0];
            } else if (entry.range !== undefined) {
                if (entry.range.default) {
                    params[entry.key] = entry.range.default.toString();
                } else {
                    params[entry.key] = "";
                }
            }
        }

        return params;
    }

    private getOption(label: string, option: IOption): JSX.Element | undefined {
        if (this.isOptionsVisible(label, option)) {
            if (option.key === "offline" && !this.isOfflineValid()) {
                return undefined;
            } else if (option.value !== undefined) {
                return (
                    <Grid.Column key={option.key}>
                        <Form.Input
                            label={label}
                            key={option.key}
                            value={this.state.params[option.key]}
                            placeholder={option.placeholder || "Not Set"}
                            onChange={(e, d) => this.onOptionUpdate(option.key, d.value as string)}
                        />
                    </Grid.Column>
                );
            } else if (option.options) {
                let options: DropdownItemProps[] = [];
    
                for (const entry of option.options) {
                    options.push({
                        key: entry,
                        value: entry,
                        text: (entry.length > 0) ? entry : "None"
                    });
                }
    
                if (label === "Operator") {
                    if (this.props.rtp.length > 0) {
                        options.push(...this.props.rtp.map((x) => { return {
                            key: x,
                            value: x,
                            text: x
                        } }));
                    }
                }
    
                if (label === "Force Behaviour") {
                    if (this.props.forces.length > 0) {
                        options.push(...this.props.forces.map((x) => { return {
                            key: `force-${x}`,
                            value: x,
                            text: x
                        } }));
                    }
                }
    
                options.push({
                    key: "Custom...",
                    value: "Custom...",
                    text: "Custom..."
                });
    
                return (
                    <Grid.Column key={option.key}>
                        <Form.Select
                            label={label}
                            key={option.key}
                            options={options}
                            placeholder={option.placeholder}
                            value={this.state.params[option.key]}
                            disabled={options.length <= 1}
                            onChange={(e, d) => this.onOptionUpdate(option.key, d.value as string)}
                        />
                    </Grid.Column>
                );
            } else if (option.range) {
                return (
                    <Grid.Column key={option.key}>
                        <Form.Input
                            label={label}
                            key={option.key}
                            type="number"
                            value={this.state.params[option.key]}
                            step={option.range.interval}
                            min={option.range.min}
                            max={option.range.max}
                            placeholder="Not Set"
                            onChange={(e, d) => this.onOptionUpdate(option.key, d.value)}
                        />
                    </Grid.Column>
                );
            } else {
                return <Grid.Column key={option.key}>{option.key}</Grid.Column>;
            }
        } else {
            return undefined;
        }
    }

    private isOptionsVisible(label: string, option: IOption): boolean {
        switch (option.key) {
            case "ns":
                return this.state.params["behaviour"] === "manual";
            case "uncertifiedShuffle":
                return this.state.params["behaviour"] === "manual";
            case "uncertifiedNs":
                return this.state.params["behaviour"] === "manual";
            default:
                return true;
        }
    }

    private onOptionUpdate = (key: string, value: string | number | undefined) => {
        const options = this.state.options;
        if (value === "Custom...") {
            for (const k in options) {
                if (options[k].key === key) {
                    options[k].value = "";
                }
            }
            value = "";
        }

        const params: { [key: string]: string } = this.state.params;
        delete params[key];

        if (typeof(value) === "string" && value.length > 0) {
            params[key] = value;
        } else if (typeof(value) === "number") {
            params[key] = value.toString();
        }

        if (!this.isOfflineValid()) {
            params["offline"] = "false";
        }

        for (const key in options) {
            if (options[key].typeOnly !== undefined && options[key].typeOnly !== this.props.gameType) {
                delete params[options[key].key];
            }
        }

        this.setState({ params: params, options: options }, () => {
            this.props.onParamsChange(this.state.params);
        });
    }

    private isOfflineValid(): boolean {
        return (this.props.branch !== "master") && (this.props.branch !== "main");
    }
}