export enum PermissionLevels {
    Unauthorized = 0,
    Authorized = 1,
    Elevated = 2,
    Administration = 10
}

export function getPermissionsLabel(level: PermissionLevels): string {
    switch (level) {
        case PermissionLevels.Administration:
            return "Administration";
        case PermissionLevels.Elevated:
            return "Elevated";
        case PermissionLevels.Authorized:
            return "Authorized";
        case PermissionLevels.Unauthorized:
            return "";
    }
}

export function validPermissions(permissions: PermissionLevels, expected: PermissionLevels): boolean {
    return permissions >= expected;
}