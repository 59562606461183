export function applyHeartsEffect(canvas: HTMLCanvasElement): void {
    const ctx = canvas.getContext("2d");
	
    if (ctx) {
        //canvas dimensions
        const W = window.innerWidth;
        const H = window.innerHeight;
        canvas.width = W;
        canvas.height = H;
        
        //heart particles
        const mp = 200; //max particles
        const particles: any[] = [];
        for(let i = 0; i < mp; i++)
        {
            particles.push({
                x: Math.random()*W, //x-coordinate
                y: (H * 0.8) + (H * 0.2 * Math.random()), //y-coordinate
                r: Math.random()*4+1, //radius
                d: Math.random()*mp //density
            })
        }
    
        //animation loop
        setInterval(() => draw(ctx, canvas, mp, particles), 33);
    }
}
	
//Lets draw the flakes
function draw(ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement, mp: number, particles: any[]): void
{
    const W = window.innerWidth;
    const H = window.innerHeight;
    canvas.width = W;
    canvas.height = H;

    ctx.clearRect(0, 0, W, H);
    
    ctx.fillStyle = "rgba(219, 39, 135, 0.8)";
    ctx.beginPath();
    for(var i = 0; i < mp; i++)
    {
        var p = particles[i];
        ctx.moveTo(p.x, p.y);
        ctx.arc(p.x, p.y, p.r, 0, Math.PI*2, true);
        ctx.arc(p.x + p.r, p.y, p.r, 0, Math.PI*2, true);
        ctx.moveTo(p.x - p.r, p.y);
        ctx.lineTo(p.x + p.r / 2, p.y + p.r * 2);
        ctx.lineTo(p.x + p.r * 2, p.y);
        ctx.lineTo(p.x - p.r, p.y);
    }
    ctx.fill();
    update(particles, W, H, mp);
}

//Function to move the snowflakes
//angle will be an ongoing incremental flag. Sin and Cos functions will be applied to it to create vertical and horizontal movements of the flakes
function update(particles: any[], W: number, H: number, mp: number): void
{
    for(let i = 0; i < mp; i++)
    {
        const p = particles[i];
        //Updating X and Y coordinates
        //We will add 1 to the cos function to prevent negative values which will lead flakes to move upwards
        //Every particle has its own density which can be used to make the downward movement different for each flake
        //Lets make it more random by adding in the radius
        p.y -= 1 + p.r/2;
        
        //Sending flakes back from the top when it exits
        //Lets make it a bit more organic and let flakes enter from the left and right also.
        if(p.y < H * (0.8 + (Math.random() * 0.1)))
        {
            particles[i] = {x: Math.random()*W, y: H, r: p.r, d: p.d};
        }
    }
}