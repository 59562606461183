import { getCorsAnywhereURL } from "./cors-anywhere";

export function getSnowflakeURL(gameURL: string): Promise<string> {
    return new Promise((resolve, reject) => {
        const request: XMLHttpRequest = new XMLHttpRequest();
        request.onload = () => {
            const url: string | null = request.getResponseHeader("x-final-url");
            if (url) {
                resolve(url);
            } else {
                reject();
            }
        }
        const targetURL: string = getCorsAnywhereURL(gameURL);
        request.open("GET", targetURL);
        request.send();
    });
}