import React from "react";
import { Button, Form, Header, Modal, Segment } from "semantic-ui-react";
import { TabComponent } from "./tab-component";

interface IProperties {
    available?: string;

    game?: string;
    client: string;
    deployed?: boolean;
    hasEverDeployed?: boolean;

    getMasterHash: () => Promise<string>;
    onDeployConfirm: (game: string, hash: string, password: string, reason: string) => void;
    onMarkAsDeploy: (game: string, password: string) => void;
}

interface IState {
    hash: string;
    reason: string;
    gettingHash: boolean;
    modalOpen1: boolean;
    modalOpen2: boolean;
    modalOpen3: boolean;
}

export class GamevyDeployment extends TabComponent<IProperties, IState> {
    constructor(props: IProperties) {
        super(props, "GamevyDeployment");

        this.state = {
            hash: "",
            reason: "",
            gettingHash: false,
            modalOpen1: false,
            modalOpen2: false,
            modalOpen3: false
        };
    }

    public render(): JSX.Element {
        if ((this.props.client === "Epic" || this.props.client === "Gamevy") && this.props.available !== undefined && this.props.game !== undefined) {
            return (
                <Segment textAlign="center">
                    <Header as="h5" textAlign="center" content="Deployment" />
                    <Form key="deployment-form">
                        { this.getDeployedStatusMessage() }
                        <Form.Input placeholder="Hash" value={this.state.hash} onChange={(e, d) => this.setState({ hash: d.value })} />
                        <Form.TextArea placeholder="Reason" value={this.state.reason} onChange={(e, d) => {
                            let reason = d.value?.toString() ?? "";
                            reason = reason.replace(/[^a-zA-Z0-9\.:/\\\-\(\)|+=_~#@?!><,\[\]\{\}\*&^%$£"\n\r ]+/g, "");
                            this.setState({ reason });
                        }} />
                        { this.getMarkAsDeployedButton() }
                        <Form.Button
                            content="Deploy"
                            primary
                            fluid
                            disabled={this.state.hash.length === 0}
                            onClick={() => {
                                this.setState({ modalOpen1: true });
                            }}
                        />
                        <Form.Button
                            content="Use Latest Master Hash"
                            fluid
                            loading={this.state.gettingHash}
                            disabled={this.state.hash.length > 0}
                            onClick={() => {
                                this.setState({ gettingHash: true }, async () => {
                                    this.setState({ hash: await this.props.getMasterHash(), gettingHash: false });
                                });
                            }}
                        />
                    </Form>
                    <Modal open={this.state.modalOpen1}>
                        <Modal.Header>Deploy Game to Live!</Modal.Header>
                        <Modal.Content>
                            Are you certain you want to deploy the hash <pre className="inline">{this.state.hash}</pre> for the game <pre className="inline">{this.props.game}</pre> live?<br/>
                            This will go out to all players! 
                        </Modal.Content>
                        <Modal.Actions>
                            <Button content="Yes" color="green" onClick={() => {
                                this.setState({ modalOpen1: false, modalOpen2: true });
                            }} />
                            <Button content="No" color="red" onClick={() => {
                                this.setState({ modalOpen1: false });
                            }} />
                        </Modal.Actions>
                    </Modal>
                    <Modal open={this.state.modalOpen2}>
                        <Modal.Header>Deploy Game to Live!</Modal.Header>
                        <Modal.Content>
                            No really!
                            <br/>
                            Are you absolutely certain you want to deploy the hash <pre className="inline">{this.state.hash}</pre> for the game <pre className="inline">{this.props.game}</pre> live?<br/>
                            <br/>
                            <span style={{ fontSize: "1.5em" }}>This will go out to all players!</span>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button content="Yes" color="green" onClick={() => {
                                this.props.onDeployConfirm(this.props.game!, this.state.hash, this.props.available!, this.state.reason);
                                this.setState({ modalOpen2: false, hash: "", reason: "" });
                            }} />
                            <Button content="No" color="red" onClick={() => {
                                this.setState({ modalOpen2: false });
                            }} />
                        </Modal.Actions>
                    </Modal>
                    <Modal open={this.state.modalOpen3}>
                        <Modal.Header>Mark Game as Already Deployed</Modal.Header>
                        <Modal.Content>
                            Are you sure you want to mark this game as having been deployed at a previous time? 
                            <span style={{ fontWeight: 600, color: "red" }}> This cannot be undone.</span>
                            <br/>
                            The deployment will be treated as if you deployed the latest master build, but will not actually deploy it.
                        </Modal.Content>
                        <Modal.Actions>
                            <Button content="Yes" color="green" onClick={() => {
                                this.setState({ modalOpen3: false });
                                this.props.onMarkAsDeploy(this.props.game!, this.props.available!);
                            }} />
                            <Button content="No" color="red" onClick={() => {
                                this.setState({ modalOpen3: false });
                            }} />
                        </Modal.Actions>
                    </Modal>
                </Segment>
            );
        } else {
            return <span></span>;
        }
    }

    private getDeployedStatusMessage(): JSX.Element {
        switch (this.props.deployed) {
            case true:
                return <p style={{ color: "green" }}>The latest master build is already deployed!</p>;
            case false:
                if (!this.props.hasEverDeployed) {
                    return <p>This games deployments are not yet tracked. They will be tracked after the next deployment.</p>;
                } else {
                    return <p style={{ color: "red" }}>The latest master build has not been deployed yet.</p>;
                }
            default:
                return <p>Checking...</p>;
        }
    }

    private getMarkAsDeployedButton(): JSX.Element {
        if (!this.props.hasEverDeployed && this.props.deployed === false) {
            return (
                <Form.Button
                    content="Mark As Already Deployed"
                    basic
                    fluid
                    onClick={() => {
                        this.setState({ modalOpen3: true });
                    }}
                />
            );
        } else {
            return <span></span>;
        }
    }
}