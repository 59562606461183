import React from "react";
import { Button, DropdownItemProps, Form, Grid, Segment } from "semantic-ui-react";
import { GameType } from "../application";
import { IsMobile } from "../utils/contextual-css";
import { IRepoData, ISnowflakeData } from "../utils/data-interfaces";

interface IProperties {
    clients: string[];
    slotworksGames: IRepoData[];
    snowflakeGames: ISnowflakeData[];
    gameType: GameType;
    branches: { [key: string]: string[] };
    server: string;
    serverOptions: string[];

    client: string;
    game: string;
    branch: string;
    version?: string;
    versionMessage?: string;
    versionMatch?: boolean;
    approved?: boolean;

    onClientChange: (client: string) => void;
    onGameChange: (game: string, type: GameType) => void;
    onBranchChange: (branch: string) => void;
    onServerChange: (server: string) => void;
    onBranchRefresh: () => void;
    onVersionRefresh: () => void;
}

interface IState {

}

export class GameSelector extends React.Component<IProperties, IState> {
    constructor(props: IProperties) {
        super(props);

        this.state = {
            
        };
    }

    public render(): JSX.Element {
        const isMobile: boolean = IsMobile();
        return (
            <Segment>
                <Grid>
                    <Grid.Row columns={(IsMobile() ? 1 : 4)}>
                        <Grid.Column width={3}>
                            <Form.Select
                                value={this.props.gameType}
                                label="Engine"
                                fluid
                                onChange={(e, d) => {
                                    const gameType = d.value as GameType;
                                    const game = (gameType === GameType.Slotworks) ? this.props.slotworksGames[0].slug : this.props.snowflakeGames[0].game;
                                    this.props.onGameChange(game, gameType);
                                }}
                                options={[
                                    { value: GameType.Slotworks, text: "Slotworks" },
                                    { value: GameType.Snowflake, text: "Snowflake" }
                                ]}
                                search={(!isMobile) ? (options, value) => {
                                    return options.filter((x) => x.text?.toString().toLowerCase().startsWith(value.toLowerCase()))
                                } : false}
                            />
                        </Grid.Column>
                        <Grid.Column width={(this.props.gameType === GameType.Slotworks) ? 4 : 4}>
                            <Form.Select
                                value={this.props.game}
                                label="Game"
                                fluid
                                onChange={(e, d) => {
                                    const game = d.value as string;
                                    this.props.onGameChange(game, this.props.gameType);
                                }}
                                options={this.getGameOptions()}
                                search={(!isMobile) ? (options, value) => {
                                    return options.filter((x) => x.text?.toString().toLowerCase().startsWith(value.toLowerCase()))
                                } : false}
                            />
                        </Grid.Column>
                        <Grid.Column width={(this.props.gameType === GameType.Slotworks) ? 5 : 9}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={(this.props.gameType === GameType.Snowflake) ? 8 : 12} style={(this.props.gameType === GameType.Snowflake) ? {} : { paddingRight: "0" }}>
                                        <Form.Select
                                            value={this.props.branch}
                                            label={(this.props.gameType === GameType.Slotworks) ? "Branch" : "Environment"}
                                            fluid
                                            onChange={(e, d) => this.props.onBranchChange(d.value as string)}
                                            options={this.getBranches()}
                                            search={!isMobile}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={2} style={{ marginTop: "auto", marginBottom: "1pt", display: (this.props.gameType === GameType.Slotworks) ? "block" : "none" }}>
                                        <Button icon="refresh" basic onClick={() => this.props.onBranchRefresh()}/>
                                    </Grid.Column>
                                    <Grid.Column width={8} style={{ marginTop: "auto", marginBottom: "1pt", display: (this.props.gameType === GameType.Snowflake) ? "block" : "none" }}>
                                        <Form.Select
                                            value={this.props.server}
                                            label={"Server"}
                                            fluid
                                            onChange={(e, d) => this.props.onServerChange(d.value as string)}
                                            options={this.props.serverOptions.map((x) => ({ value: x, text: x, key: x }))}
                                            search={!isMobile}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column width={4} style={{ display: (this.props.gameType === GameType.Slotworks) ? "block" : "none"}}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={11} style={{ paddingRight: "0" }}>
                                        <Form.Field
                                            label="Version"
                                        />
                                        <p style={{
                                            paddingTop: "7pt",
                                            fontWeight: "bold",
                                            color: (this.props.version && this.props.versionMatch !== undefined) ?
                                                ((this.props.versionMatch) ? "#00AA00" : "#AA0000") : undefined
                                        }}>
                                            { (this.props.version === "0.0.0") ? "Building..." : (this.props.version ?? "Loading...") }
                                            <span style={{ display: "block", color: "#444444", fontSize: 10 }}>{ this.getVersionMessage() }</span>
                                        </p>
                                    </Grid.Column>
                                    <Grid.Column width={4} style={{ marginTop: "16pt", marginBottom: "-7pt" }}>
                                        <Button disabled={this.props.version === undefined} icon="refresh" basic onClick={() => this.props.onVersionRefresh()}/>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        );
    }

    private getBranches(): DropdownItemProps[] {
        const branches = (this.props.game in this.props.branches) ? this.props.branches[this.props.game] : [];
        const values: DropdownItemProps[] = branches.map(x => {
            return { value: x, text: x }
        });
        if (this.props.gameType === GameType.Slotworks) {
            values.unshift({ value: "", text: "-", disabled: true });
            if (values.some((x) => x.value === "demo")) {
                const removed = values.splice(values.findIndex((x) => x.value === "demo"), 1);
                values.unshift(...removed);
            }
            if (values.some((x) => x.value === "develop")) {
                const removed = values.splice(values.findIndex((x) => x.value === "develop"), 1);
                values.unshift(...removed);
            }
            if (values.some((x) => x.value === "master")) {
                const removed = values.splice(values.findIndex((x) => x.value === "master"), 1);
                values.unshift(...removed);
            }
            if (values.some((x) => x.value === "main")) {
                const removed = values.splice(values.findIndex((x) => x.value === "main"), 1);
                values.unshift(...removed);
            }
        }
        return values;
    }

    private getVersionMessage(): string {
        if (this.props.versionMessage) {
            return ` ${this.props.versionMessage}`;
        } else if (this.props.branch === "master" || this.props.branch === "main") {
            switch (this.props.approved) {
                case false:
                    return " (Pending QA)";
                default:
                    return "";
            }
        } else {
            return "";
        }
    }

    private getGameOptions(): DropdownItemProps[] {
        const slotworksGames = this.props.slotworksGames.map(x => { return { value: `${x.slug}`, text: this.prettifyName(x.name) } });
        const snowflakeGames = this.props.snowflakeGames.map(x => { return { value: `${x.game}`, text: this.prettifyName(x.game) } });

        switch (this.props.gameType) {
            case GameType.Slotworks:
                return slotworksGames;
            case GameType.Snowflake:
                return snowflakeGames;
        }
    }

    private prettifyName(name: string): string {
        const words = name.toLowerCase().split(/[-_]/);
        return words.map((x) => {
            return x[0].toUpperCase() + x.substring(1);
        }).join(" ");
    }
}
