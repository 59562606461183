export function downloadFile(url: string, filename: string, ...headers: { key: string, value: string }[]): Promise<boolean> {
    return new Promise((resolve) => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        for (const header of headers) {
            xhr.setRequestHeader(header.key, header.value);
        }
        xhr.responseType = 'blob';
        xhr.onload = () => {
            if (xhr.status === 200) {
                const blob = xhr.response;
        
                const a = document.createElement("a");
                a.href = window.URL.createObjectURL(blob);
                a.download = filename;
                a.click();
                resolve(true);
            } else {
                resolve(false);
            }
        };
        xhr.onerror = (e) => {
            console.error(e);
            resolve(false);
        };
        xhr.send();
        console.log("Downloading...", url, filename);
    });
}


export function downloadText(filename: string, data: string): void {
    const a = document.createElement("a");
    a.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(data);
    a.download = filename;
    a.click();
}