import React from "react";
import { Button, Container, Dimmer, Divider, Dropdown, Image, Loader, Menu, Message, Popup, Segment, Tab } from "semantic-ui-react";
import { io, Socket } from "socket.io-client";
import { GameOptions } from "./components/game-options";
import { GameSelector } from "./components/game-selector";
import { Login } from "./components/login";
import { copyToClipboard } from "./utils/copy-to-clipboard";
import { IRepoData, ISnowflakeData } from "./utils/data-interfaces";
import { OpenLink } from "./utils/open-link";
import { getSessionID } from "./utils/session-id";
import queryString from "query-string";
import { getDeploymentPath, getRepo } from "./data/deployment-links";
import { GetCommitHash } from "./utils/commit-hash";
import { AdditionalLinks } from "./components/additional-links";
import { FreeRounds } from "./components/free-rounds";
import { PlaySound } from "./utils/play-sound";
import { Release } from "./components/release";
import { LoginMenu } from "./components/login-menu";
import { GamevyDeployment } from "./components/gamevy-deployment";
import { GameFiles } from "./components/game-files";
import { QAApproval } from "./components/qa-approval";
import { IsMobile } from "./utils/contextual-css";
import { PermissionLevels, validPermissions } from "./permissions";
import { LibVersions } from "./components/lib-versions";
import { applySnowEffect } from "./effects/canvas-snow";
import { applyHeartsEffect } from "./effects/canvas-hearts";
import { getSnowflakeURL } from "./utils/snowflake-url";
import { SnowflakeBranchDefault, SnowflakeBranches, SnowflakeServerDefault, SnowflakeServers } from "./data/options";
import { downloadText } from "./utils/download-file";
import { Profile as GoogleProfile } from "passport-google-oauth20";

enum Seasonal {
    None = "none",
    Xmas = "xmas",
    Valentine = "valentine",
    Easter = "easter"
}

export enum GameType {
    Slotworks = "slotworks",
    Snowflake = "snowflake"
}

interface IProperties {

}

interface IState {
    loading: boolean;
    loadingMessage: string;

    loginUsername: string;
    loginEmail?: string;

    username: string;
    permissions: PermissionLevels;
    slotworksGames: IRepoData[];
    snowflakeGames: ISnowflakeData[];
    gameType: GameType;
    branches: { [key: string]: string[] };
    snowflakeServer: string;

    client: string;
    game: string;
    defaultBranch: string;
    branch: string;
    version?: string;
    versionMatch?: boolean;

    releasePending: boolean;
    checkingRelease: boolean;
    approved?: boolean;
    deployed?: boolean;
    hasEverDeployed?: boolean;

    params: { [key: string]: string };

    libs?: { repo: string, libs: { [key: string]: string } };
    libLoading: boolean;
    
    history: string[];

    rtp: string[];

    seasonal: string;
    previousSeasonal: string;

    message?: string;
    error?: string;

    initialising: boolean;
}

export class Application extends React.Component<IProperties, IState> {
    private readonly requireAuth: boolean = false;

    private socket: Socket;
    private messageTimer?: number;
    private canvas?: HTMLCanvasElement;

    constructor(props: IProperties) {
        super(props);

        const history: string[] = JSON.parse(localStorage.getItem("user_history") ?? "[]");

        let seasonal: string = Seasonal.None;

        const date = new Date(Date.now());
        if (date.getMonth() === 11) {
            seasonal = Seasonal.Xmas;
        } else if (date.getMonth() === 1 && date.getDate() === 14) {
            seasonal = Seasonal.Valentine;
        }

        this.state = {
            loading: true,
            loadingMessage: "Initialising...",

            loginUsername: "guest",
            permissions: PermissionLevels.Unauthorized,

            username: "",
            slotworksGames: [],
            snowflakeGames: [],
            gameType: GameType.Slotworks,
            branches: {},
            snowflakeServer: SnowflakeServerDefault,

            releasePending: false,
            checkingRelease: true,

            client: "Gamevy",
            game: "",
            branch: "",
            defaultBranch: "",

            params: {},

            history: history,

            libLoading: false,

            seasonal,
            previousSeasonal: "",

            rtp: [],

            initialising: true
        };

        this.socket = io();
        (window as any).socket = this.socket;
        (window as any).forceLoading = () => this.setState({ loading: true });

        this.setupSocket();
    }

    public componentDidMount(): void {
        this.processURLParams();
        this.setupCanvas();

        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 500);

        // document.addEventListener("visibilitychange", (event) => {
        //     if (document.visibilityState !== "visible") {
        //         this.clearLogin();
        //         console.log("Login cleared due to loss of window visibility.")
        //     }
        // });
    }

    public componentDidUpdate(): void {
        this.setupCanvas();

        document.body.classList.remove(...Object.values(Seasonal));
        document.body.classList.add(this.state.seasonal);
    }

    public render(): JSX.Element {
        if (this.state.initialising) {
            return (
                <Container style={{ overflow: "hidden" }}>
                    <Dimmer active={true} page>
                        <Loader>Please wait...</Loader>
                    </Dimmer>
                </Container>
            );
        } else if (this.state.permissions === PermissionLevels.Unauthorized && this.requireAuth) {
            return (
                <Container style={{ overflow: "hidden" }}>
                    <Dimmer active={this.state.loading} page>
                        <Loader content={this.state.loadingMessage} />
                    </Dimmer>
                    <Divider hidden />
                    <LoginMenu username={this.state.loginUsername} permissions={this.state.permissions} alwaysOpen={true} />
                    { this.renderLogo() }
                </Container>
            );
        } else {
            return (
                <Container style={{ paddingBottom: "5pt" }}>
                    <Dimmer active={this.state.loading} page>
                        <Loader content={this.state.loadingMessage} />
                    </Dimmer>
                    <Divider hidden />
                    <LoginMenu username={this.state.loginUsername} permissions={this.state.permissions} />
                    <div style={{ display: "table", textAlign: "left", color: "white", height: 36, margin: "auto", marginTop: -36, marginLeft: 0 }}>
                        <Menu>
                            <Dropdown basic compact item simple icon="align justify">
                                <Dropdown.Menu>
                                    <Dropdown.Header>Tools</Dropdown.Header>
                                    <Dropdown.Item onClick={() => OpenLink("https://games.gamevy.com/launcher.html")}>Launcher</Dropdown.Item>
                                    <Dropdown.Item onClick={() => OpenLink("/cui/")}>CUI Updater</Dropdown.Item>
                                    <Dropdown.Item onClick={() => OpenLink("/iframe/")}>iFrame Testing</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu>
                    </div>
                    { this.renderLogo() }
                    <Segment style={{ backgroundColor: "#F0F0F0" }}>
                        <Login
                            value={this.state.username}
                            history={this.state.history}
                            onChange={(username) => this.setState({username})}
                        />
                        <GameSelector
                            clients={[ "Gamevy" ]}
                            slotworksGames={this.state.slotworksGames}
                            snowflakeGames={this.state.snowflakeGames}
                            gameType={this.state.gameType}
                            branches={this.state.branches}
                            server={this.state.snowflakeServer}
                            serverOptions={SnowflakeServers}
    
                            client={this.state.client}
                            game={this.state.game}
                            branch={this.state.branch}
                            version={this.state.version?.split("#")[0]}
                            versionMessage={this.state.version?.split("#")[1]}
                            versionMatch={this.state.versionMatch}
                            approved={this.state.approved}
    
                            onClientChange={(client) => this.setState({ client }, () => {
                                const game: string = this.state.slotworksGames[0].slug;
                                const defaultBranch: string = this.state.slotworksGames[0].defaultBranch;
                                this.setState({ game, defaultBranch: defaultBranch, branch: defaultBranch, loading: true }, () => {
                                    this.socket.emit("branches", game);
                                    const gameid: string | undefined = this.state.slotworksGames.find(x => x.slug === game)?.id;
                                    if (gameid) {
                                        this.setState({ rtp: [] }, () => {
                                            this.socket.emit("rtpvalues", gameid);
                                        });
                                    }
                                });
                            })}
                            onGameChange={(game, gameType) => {
                                const loadBranches: boolean = !(game in this.state.branches);
                                const defaultBranch = this.state.slotworksGames.find(x => x.slug === game)?.defaultBranch ?? "master";
                                this.setState({ game, gameType, defaultBranch: defaultBranch, branch: defaultBranch, loading: loadBranches }, async () => {
                                    if (gameType === GameType.Slotworks) {
                                        if (loadBranches) {
                                            this.socket.emit("branches", game);
                                        } else {
                                            this.setState({ checkingRelease: true, releasePending: false, version: undefined, versionMatch: undefined, approved: undefined, deployed: undefined, hasEverDeployed: undefined, libs: undefined, libLoading: true });
                                            this.socket.emit("releaseinfo", this.state.game, defaultBranch);
                                            this.socket.emit("branchversion", this.state.game, this.state.branch, await this.generateGameLink());
        
                                            this.socket.emit("is-approved", this.state.game, defaultBranch);
                                            this.socket.emit("is-deployed", this.state.game);
                                            this.socket.emit("lib-versions", this.state.game);
                                        }
                                        const gameid: string | undefined = this.state.slotworksGames.find(x => x.slug === game)?.id;
                                        if (gameid) {
                                            this.setState({ rtp: [] }, () => {
                                                this.socket.emit("rtpvalues", gameid);
                                            });
                                        }
                                    } else {
                                        const gameid: string = game;
                                        const branches = this.state.branches;
                                        branches[game] = SnowflakeBranches;
                                        this.setState({ game, branches, branch: SnowflakeBranchDefault, checkingRelease: true, releasePending: false, version: undefined, versionMatch: undefined, approved: undefined, deployed: undefined, hasEverDeployed: undefined, libs: undefined, libLoading: false, loading: false });
                                        this.setState({ rtp: [] }, () => {
                                            this.socket.emit("rtpvalues", gameid);
                                        });
                                    }
                                });
                            }}
                            onBranchChange={(branch) => {
                                this.setState({ branch, version: undefined, versionMatch: undefined, libLoading: true }, async () => {
                                    this.socket.emit("branchversion", this.state.game, this.state.branch, await this.generateGameLink());
                                    this.socket.emit("lib-versions", this.state.game);
                                    this.socket.emit("is-approved", this.state.game, this.state.defaultBranch);
                                    this.socket.emit("is-deployed", this.state.game);
                                });
                            }}
                            onBranchRefresh={() => {
                                this.setState({ loading: true }, () => {
                                    this.socket.emit("branches", this.state.game, true);
                                    this.socket.emit("is-approved", this.state.game, this.state.defaultBranch);
                                    this.socket.emit("is-deployed", this.state.game);
                                });
                            }}
                            onVersionRefresh={() => {
                                this.setState({ version: undefined, versionMatch: undefined}, async () => {
                                    this.socket.emit("branchversion", this.state.game, this.state.branch, await this.generateGameLink());
                                    this.socket.emit("is-approved", this.state.game, this.state.defaultBranch);
                                    this.socket.emit("is-deployed", this.state.game);
                                });
                            }}
                            onServerChange={(server) => {
                                this.setState({ snowflakeServer: server });
                            }}
                        />
                        { this.renderLaunchSection() }
                        <Tab menu={(IsMobile()) ? { fluid: true } : undefined} panes={[
                            { menuItem: "Parameters", render: () => (
                                <Tab.Pane>
                                    <GameOptions
                                        client={this.state.client}
                                        branch={this.state.branch}
                                        gameType={this.state.gameType}
                                        username={this.state.username}
                                        rtp={this.state.rtp}
                                        forces={this.getSnowflakeForces()}
                                        onParamsChange={(params) => {
                                            const forceSelected: boolean = (params["behaviour"]?.trim().length ?? 0) > 0;
                                            if (forceSelected) {
                                                this.setState({ params, username: "" });
                                            } else {
                                                this.setState({ params });
                                            }
                                        }}
                                    />
                                </Tab.Pane>
                            )},
                            (this.state.gameType === GameType.Slotworks) ? 
                            { menuItem: "Libs", render: () => (
                                <Tab.Pane>
                                    <LibVersions
                                        game={this.state.game}
                                        libs={this.state.libs}
                                        loading={this.state.libLoading}
                                    />
                                </Tab.Pane>
                            ) } : undefined,
                            { menuItem: "Links", render: () => (
                                <Tab.Pane>
                                    <AdditionalLinks
                                        gameID={(this.state.gameType === GameType.Snowflake) ? this.state.game : this.state.slotworksGames.find(x => x.slug === this.state.game)?.id}
                                        game={this.state.game}
                                        client={this.state.client}
                                        gameType={this.state.gameType}
                                        defaultBranch={(this.state.gameType === GameType.Snowflake) ? "" : this.state.slotworksGames.find(x => x.slug === this.state.game)?.defaultBranch}
                                        deployment={(this.state.gameType === GameType.Snowflake) ? this.state.game : this.state.slotworksGames.find(x => x.slug === this.state.game)?.deployment}
                                        showMessage={(message, error) => this.showMessage(message, error)}
                                        generateParameters={() => this.generateParameters()}
                                    />
                                </Tab.Pane>
                            ) },
                            { menuItem: "Tools", render: () => (
                                <Tab.Pane>
                                    <FreeRounds
                                        gameID={(this.state.gameType === GameType.Snowflake) ? this.state.game : this.state.slotworksGames.find(x => x.slug === this.state.game)?.id}
                                        username={this.state.username}
                                        showMessage={(message, error) => this.showMessage(message, error)}
                                    />
                                    <GameFiles
                                        available={validPermissions(this.state.permissions, PermissionLevels.Authorized) ? this.state.loginEmail : undefined}
                                        game={this.state.slotworksGames.find(x => x.slug === this.state.game)?.deployment}
                                        client={this.state.client}
                                        currency={this.state.params["currency"]}
                                        jurisdiction={this.state.params["jurisdiction"]}
                                        operator={this.state.params["operator"]}
                                        requestBelarus={(game: string) => this.setState({
                                            loading: true,
                                            loadingMessage: "Getting hash data. This may take a few minutes the first time."
                                        }, () => this.socket.emit("belarus-hash", game))}
                                    />
                                    {/* <CUIUpdater
                                        available={this.state.loginToken}
                                        onClick={(version, password) => {
                                            this.socket.emit("cuiupdate", this.state.game, version, password);
                                            this.setState({ loading: true });
                                        }}
                                        onClickAll={(version, password) => {
                                            this.socket.emit("cuiupdateall", this.state.client, version, password);
                                            this.setState({ loading: true });
                                        }}
                                    /> */}
                                </Tab.Pane>
                            )},
                            (this.state.gameType === GameType.Slotworks) ? 
                            { menuItem: "DevOps", render: () => (
                                <Tab.Pane>
                                    <QAApproval
                                        available={validPermissions(this.state.permissions, PermissionLevels.Elevated) ? this.state.loginEmail : undefined}
                                        approved={this.state.approved}
                                        game={this.state.game}
                                        branch={this.state.defaultBranch}
                                        onApprove={(approved: boolean, game: string, branch: string) => {
                                            this.socket.emit((approved) ? "approve-branch" : "unapprove-branch", game, this.state.defaultBranch, this.state.loginEmail);
                                            this.setState({ loading: true, approved: undefined });
                                        }}
                                    />
                                    <Release
                                        available={validPermissions(this.state.permissions, PermissionLevels.Elevated) ? this.state.loginEmail : undefined}
                                        game={this.state.game}
                                        releasePending={this.state.releasePending}
                                        checkingRelease={this.state.checkingRelease}
                                        onReleaseConfirm={(game: string, password: string) => {
                                            this.socket.emit("release", game, password);
                                            this.setState({ loading: true });
                                            this.showMessage(`Releasing ${game}...`, false);
                                        }}
                                    />
                                    <GamevyDeployment
                                        available={validPermissions(this.state.permissions, PermissionLevels.Elevated) ? this.state.loginEmail : undefined}
                                        game={this.state.slotworksGames.find(x => x.slug === this.state.game)?.deployment}
                                        client={this.state.client}
                                        deployed={this.state.deployed}
                                        hasEverDeployed={this.state.hasEverDeployed}
                                        getMasterHash={async () => GetCommitHash(await this.generateGameLink())}
                                        onDeployConfirm={(game, hash, password, reason) => {
                                            this.socket.emit("deploy", getRepo(game), this.state.game, hash, password, this.state.loginUsername, reason);
                                            this.setState({ loading: true });
                                        }}
                                        onMarkAsDeploy={(game, password) => {
                                            this.socket.emit("mark-as-deployed", this.state.game, password);
                                            this.setState({ loading: true });
                                        }}
                                    />
                                </Tab.Pane>
                            ) } : undefined
                        ].filter((x) => x !== undefined) as any} />
                    </Segment>
                    <Message
                        content={this.state.message ?? this.state.error}
                        className="fixed-message"
                        style={{ display: (this.state.message !== undefined || this.state.error !== undefined) ? "block": "none" }}
                        error={this.state.error !== undefined}
                    />
                </Container>
            );
        }
    }

    private renderLaunchSection(): JSX.Element {
        return (
            <Segment>
                <Button.Group fluid attached="top">
                    <Button content="Launch" primary onClick={(async () => {
                        OpenLink(await this.generateGameLink());
                        this.updateUserHistory();
                    })} />
                    <Popup content="Open game in the iFrame tool" position="top right" trigger={
                        <Button icon="window restore outline" style={{ maxWidth: "30pt" }} primary onClick={(async () => {
                            OpenLink(`https://games-portal.gamevy.com/iframe/?url=${encodeURIComponent(await this.generateGameLink())}`);
                            this.updateUserHistory();
                        })} />
                    } />
                </Button.Group>
                <Button.Group fluid attached="bottom" widths="8">
                    <Button content="Copy Parameters" onClick={( async () => {
                        const operator = this.state.params["operator"] ?? "gamevy";
                        const platform = this.state.params["platform"] ?? "gamevy";
                        copyToClipboard(`?${await this.generateAuth(this.state.username, operator, platform)}&${this.generateParameters()}`);
                        this.showMessage("Copied Parameters", false);
                    })} />
                    <Button content="Copy Permanent Link" onClick={( async () => {
                        const link: string = `${location.href}` +
                            `?username=${this.state.username}` +
                            `&game=${(this.state.gameType === GameType.Slotworks) ? this.getDeploymentPath() : this.state.game}` +
                            `&gameType=${this.state.gameType}` +
                            `&branch=${this.state.branch}` +
                            `&parameters=${encodeURIComponent(this.generateParameters())}`;
                        this.socket.emit("generate-short-url", link);
                    })} />
                    <Button content="Copy Hash" onClick={(async () => {
                        const hash: string = await GetCommitHash(await this.generateGameLink());
                        copyToClipboard(hash);
                        this.showMessage("Copied Hash", false);
                    })} />
                    <Button content="Events Test" onClick={(async () => {
                        OpenLink(`${location.protocol}//${location.host}/extra/post-message-debugger.htm?url=${encodeURIComponent(await this.generateGameLink())}`);
                    })} />
                    <Button content="GProxy" disabled={this.state.slotworksGames.find((x) => x.slug === this.state.game)?.id === undefined} onClick={(async () => {
                        OpenLink(await this.generateNYXLink());
                        this.updateUserHistory();
                    })} />
                </Button.Group>
                { this.renderSeasonal() }
            </Segment>
        );
    }

    private renderLogo(): JSX.Element {
        switch (this.state.seasonal) {
            case Seasonal.Xmas:
                return <Image centered size="medium" src="static/logo-white-xmas.png" style={{ paddingBottom: "10pt" }} />;
            case Seasonal.Easter:
                return <Image centered size="medium" src="static/logo-white-easter.png" style={{ paddingBottom: "10pt" }} />;
            default:
                return <Image centered size="medium" src="static/logo-white.png" style={{ paddingBottom: "10pt" }} />;
        }
    }

    private setupCanvas(): void {
        if (this.state.seasonal !== this.state.previousSeasonal) {
            if (this.canvas) {
                document.body.removeChild(this.canvas);
                this.canvas = undefined;
            }
    
            if (!IsMobile()) {
                this.canvas = document.createElement("canvas");
                this.canvas.classList.add("seasonal");
                document.body.appendChild(this.canvas);
    
                switch (this.state.seasonal) {
                    case Seasonal.Xmas:
                        applySnowEffect(this.canvas);
                        break;
                    case Seasonal.Valentine:
                        applyHeartsEffect(this.canvas);
                }
            }

            this.setState({ previousSeasonal: this.state.seasonal });
        }
    }

    private renderSeasonal(): JSX.Element {
        switch (this.state.seasonal) {
            case Seasonal.Xmas:
                return (
                    <div className="seasonal-background">
                        <img id="xmas-tree" src="static/xmas-tree.png" />
                    </div>
                );
            default:
                return <span></span>;
        }
    }

    private updateUserHistory(): void {
        const history: string[] = JSON.parse(localStorage.getItem("user_history") ?? "[]");
        if (history.indexOf(this.state.username) !== -1) {
            history.splice(history.indexOf(this.state.username), 1);
        }
        history.unshift(this.state.username);
        if (history.length > 5) {
            history.pop();
        }
        this.setState({ history });
        localStorage.setItem("user_history", JSON.stringify(history));
    }

    private showMessage(message: string, error: boolean): void {
        if (error) {
            this.setState({ message: undefined, error: message });
        } else {
            this.setState({ message: message, error: undefined });
        }

        if (this.messageTimer) {
            window.clearTimeout(this.messageTimer);
        }
        this.messageTimer = window.setTimeout(() => this.setState({ message: undefined, error: undefined }), 2000);
    }

    private async generateGameLink(branch: string = this.state.branch): Promise<string> {
        switch (this.state.gameType) {
            case GameType.Slotworks:
                const operator = this.state.params["operator"] ?? "gamevy";
                const platform = this.state.params["platform"] ?? "gamevy";
                return `https://games.gamevy.com/epic/${this.getDeploymentPath()}/${branch}/index.html?${await this.generateAuth(this.state.username, operator, platform)}&${this.generateParameters()}`;
            case GameType.Snowflake:
                const forceSelected: boolean = (this.state.params["behaviour"]?.trim().length ?? 0) > 0;
                const username: string = (forceSelected) ? "" : this.state.username;
                const mode: string = (username.length > 0) ? "real" : "fun";
                const url: string = await getSnowflakeURL(`https://bornlucky-${this.state.branch}-${this.state.snowflakeServer}.gamevy.com/gamevy/launch-game?gameId=${this.state.game}&playerId=${username}&mode=${mode}&${this.generateParameters()}`);
                const test: boolean = (this.state.params["behaviour"]?.length ?? 0) > 0;
                if (test) {
                    let modURL = url.replace("index.html", "test.html") + 
                        `&behaviour=${this.state.params["behaviour"]}`;
                    if (this.state.params["behaviour"] === "manual") {
                        modURL += `&serverFakeSpin=true`;
                    }
                    const params = this.generateParameterList();
                    const forceParams: string[] = [ "ns", "uncertifiedShuffle", "uncertifiedNs" ];
                    for(const p of forceParams) {
                        const result = params.list.find(x => x.startsWith(`${p}=`));
                        if (result) {
                            modURL += `&${result}`;
                        }
                    }
                    return modURL;
                } else {
                    return url;
                }
        }
        
    }

    private generateNYXLink(branch: string = this.state.branch): Promise<string> {
        return new Promise(async (resolve) => {
            this.socket.once("nyx-ogs-url", (url: string) => {
                resolve(`http://${location.host.split(":")[0]}${url}`);
            })
            const gameurl = await this.generateGameLink(branch);
            const gameId = this.state.slotworksGames.find((x) => x.slug === this.state.game)?.id;
            this.socket.emit("nyx-ogs-url", gameId, this.state.params, gameurl);
        });
    }

    private getDeploymentPath(): string {
        return getDeploymentPath(this.state.slotworksGames.find(x => x.slug === this.state.game)!.deployment);
    }

    private async generateAuth(username: string, operator: string, platform: string): Promise<string> {
        const gameId: string | undefined = this.state.slotworksGames.find((x) => x.slug === this.state.game)?.id;
        const auth: string = (username.length > 0) ? `sid=${await getSessionID(username, operator, platform, gameId)}&mode=real` : "mode=fun";
        return auth;
    }

    private generateParameterList(): { list: string[], extra: string } {
        const copy = JSON.parse(JSON.stringify(this.state.params));

        for (const key of Object.keys(copy)) {
            if (copy[key] === "false" || copy[key] === "") {
                delete copy[key];
            }
        }

        let extra: string = copy.extra ?? "";
        delete copy["extra"];
        if (extra.length > 0 && !extra.startsWith("&")) {
            extra = "&" + extra;
        }

        const list: string[] = Object.keys(copy)
            .map(x => `${x}=${copy[x]}`);

        return { list, extra };
    }

    private generateParameters(): string {
        const params = this.generateParameterList();
        return `${params.list.join("&")}${params.extra}`;
    }

    private async processURLParams(): Promise<void> {
        const query = queryString.parse(location.search);
        if (("username" in query) && ("game" in query) && ("branch" in query) && ("parameters" in query)) {
            const params = decodeURIComponent(query.parameters as string).split("&");
            if (query.gameType === GameType.Slotworks || query.gameType === undefined) {
                const operator = params.find((x) => x.startsWith("operator="))?.replace("operator=", "") ?? "gamevy";
                const platform = params.find((x) => x.startsWith("platform="))?.replace("platform=", "") ?? "gamevy";
                const auth: string = await this.generateAuth(query.username as string, operator, platform);
                const link: string = `https://games.gamevy.com/epic/${query.game}/${query.branch}/index.html?${auth}&${decodeURIComponent(query.parameters as string)}`;
                setTimeout(() => location.replace(link), 1000);
            } else {
                const username: string = query.username as string;
                const mode: string = (username.length > 0) ? "real" : "fun";
                const behaviour = params.find((x) => x.startsWith("behaviour="))?.replace("behaviour=", "") ?? "";
                const test: boolean = behaviour.length > 0;
                const server: string = query.server as string ?? SnowflakeServerDefault;
                const url: string = await getSnowflakeURL(`https://bornlucky-${query.branch}-${server}.gamevy.com/gamevy/launch-game?gameId=${query.game}&playerId=${username}&mode=${mode}&${decodeURIComponent(query.parameters as string)}`);
                const link: string = (test) ? url.replace("index.html", "test.html") + `&behaviour=${behaviour}` : url;
                setTimeout(() => location.replace(link), 1000);
            }
        } else {
            if ("seasonal" in query) {
                this.setState({ seasonal: query.seasonal as string });
            } else if (Object.keys(query).length > 0) {
                location.replace(`${location.protocol}//${location.host}`);
            }

            this.setState({ initialising: false });
        }
    }

    private setBranches(game: string, branches: string[]): void {
        this.state.branches[game] = branches.sort();
        this.setState({ branches: this.state.branches, releasePending: false, checkingRelease: true, loading: false, version: undefined, approved: undefined, deployed: undefined, hasEverDeployed: undefined, libs: undefined, libLoading: true }, async () => {
            this.socket.emit("releaseinfo", this.state.game, this.state.defaultBranch);
            this.socket.emit("branchversion", this.state.game, this.state.branch, await this.generateGameLink());
            this.socket.emit("is-approved", this.state.game, this.state.defaultBranch);
            this.socket.emit("is-deployed", this.state.game);
            this.socket.emit("lib-versions", this.state.game);
        });
    }

    private updateReleaseInfo(game: string, releasePending: boolean): void {
        if (game === this.state.game) {
            this.setState({ releasePending, checkingRelease: false });
        }
    }

    private setRepositories(slotworksGames: IRepoData[], snowflakeGames: ISnowflakeData[]): void {
        slotworksGames.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
            } else {
                return 0;
            }
        });
        snowflakeGames.sort((a, b) => {
            if (a.game.toLowerCase() < b.game.toLowerCase()) {
                return -1;
            } else if (a.game.toLowerCase() > b.game.toLowerCase()) {
                return 1;
            } else {
                return 0;
            }
        });
        const game: string = (this.state.gameType === GameType.Slotworks) ? slotworksGames[0].slug : snowflakeGames[0].game;
        if (this.state.gameType === GameType.Slotworks) {
            const loadBranches: boolean = !(game in this.state.branches);
            this.setState({ slotworksGames, snowflakeGames, game, loading: loadBranches, loadingMessage: "" }, () => {
                if (loadBranches) {
                    this.socket.emit("branches", game);
                    const gameid: string | undefined = this.state.slotworksGames.find(x => x.slug === this.state.game)?.id;
                    if (gameid) {
                        this.setState({ rtp: [] }, () => {
                            this.socket.emit("rtpvalues", gameid);
                        });
                    }
                }
            });
        } else {
            const branches: { [key: string]: string[] } = {};
            branches[game] = [ "test", "staging", "prod" ];
            this.setState({ slotworksGames, snowflakeGames, game, branch: "staging", branches, loading: false, loadingMessage: "", rtp: [] }, () => {
                this.socket.emit("rtpvalues", this.state.game);
            });
        }
    }

    private showCUIUpdateResults(success: boolean, error: string | undefined, branch: string): void {
        this.setState({ loading: false });
        this.showMessage(
            (success) ? "CUI Updated!" : `${branch}: ${error ?? "An unknown error occurred."}`,
            !success
        );
        PlaySound("static/alarm.wav");

        if (success) {
            this.socket.emit("slack", `CUI Update Complete! <!here>\nGame: ${this.state.game}, Branch: ${this.state.branch}`);
        } else {
            this.socket.emit("slack", `CUI Update Failed! <!here>\nGame: ${this.state.game}, Branch: ${this.state.branch}\nError: ${error ?? "An unknown error occurred."}`);
        }
    }

    private showCUIMultipleUpdateResults(success: boolean, error: string | undefined, responses: any[]): void {
        this.setState({ loading: false });
        this.showMessage(
            (success) ? "CUI Updated!" : `${error ?? "An unknown error occurred."}`,
            !success
        );
        if (responses.length > 0) {
            console.log(responses);
        }
        PlaySound("static/alarm.wav");

        const failures = responses.filter((response) => !response.success);
        let message: string = "CUI Update Complete! <!here>\n";
        message += `Successes: ${responses.length - failures.length}, Failures: ${failures.length}\n`;
        failures.forEach((response) => {
            message += "```\n";
            message += `Game: ${response.repo}, Branch: ${response.branch}, Version: ${response.version}\n`;
            message += `Error: ${response.error ?? "Unknown error."}\n`;
            message += "```\n";
        });
        this.socket.emit("slack", message);
    }

    private stopLoadingAndShowMessage(success: boolean, message: string): void {
        this.setState({ loading: false }, () => {
            this.showMessage(message, !success);
            this.socket.emit("is-approved", this.state.game, this.state.defaultBranch);
            this.socket.emit("is-deployed", this.state.game);
        });
    }

    private updateVersionStatus(game: string, branch: string, version: string, match?: boolean): void {
        if (game === this.state.game && branch === this.state.branch) {
            this.setState({ version, versionMatch: match });
        }
    }

    private setRTPValues(gameid: string, rtp: string[]): void {
        if (this.state.slotworksGames.find(x => x.slug === this.state.game)?.id === gameid) {
            this.setState({ rtp });
        }
    }

    private getSnowflakeForces(): string[] {
        const forces = this.state.snowflakeGames.find((x) => x.game === this.state.game)?.forces ?? [];
        return ["manual", ...forces];
    }

    private validateLogin(success: boolean): void {
        console.log("Validated:", success);
        if (!success) {
            console.log("clearing on invalid");
            this.clearLogin();
        }
    }

    private clearLogin(): void {
        this.setState({ loginUsername: "guest", loginEmail: undefined, permissions: PermissionLevels.Unauthorized });
    }

    private handleApprovalResponse(success: boolean): void {
        this.setState({ loading: false }, () => {
            this.showMessage((success) ? "Success!" : "Failed...", !success);
            this.socket.emit("is-approved", this.state.game, this.state.defaultBranch);
        });
    }

    private updateApprovalStatus(game: string, approved: boolean): void {
        if (this.state.game === game) {
            this.setState({ approved });
        }
    }

    private updateDeploymentStatus(game: string, deployed: boolean, hasEverDeployed: boolean, stopLoad: boolean): void {
        if (this.state.game === game) {
            const loading = (stopLoad) ? false : this.state.loading;
            this.setState({ deployed, hasEverDeployed, loading });
        }
    }

    private updateLibs(game: string, libs?: { repo: string, libs: { [key: string]: string} }): void {
        if (this.state.game === game) {
            this.setState({ libs, libLoading: false });
        }
    }

    private copyShortURL(short: string): void {
        copyToClipboard(`${location.href}${short}`);
        this.showMessage("Copied Permanent Link", false);
    }

    private handleBelarusHashResponse(game: string, result: string | null): void {
        this.setState({ loading: false, loadingMessage: "" }, () => {
            if (result) {
                downloadText(`${game}-belarus-hash.txt`, result);
            } else {
                this.showMessage("The hash failed to download. Check TeamCity for errors.", true);
            }
        });
    }

    private setupSocket(): void {
        this.socket.on("branches", (game: string, branches: string[]) => this.setBranches(game, branches));
        this.socket.on("releaseinfo", (game: string, modified: boolean) => this.updateReleaseInfo(game, modified));
        this.socket.on("repositories", (slotworksGames: IRepoData[], snowflakeGames: ISnowflakeData[]) => this.setRepositories(slotworksGames, snowflakeGames));
        this.socket.emit("repositories");

        this.socket.on("cuiupdate", (success: boolean, error: string | undefined, branch: string) => this.showCUIUpdateResults(success, error, branch));
        this.socket.on("cuiupdateall", (success: boolean, error: string | undefined, responses: any[]) => this.showCUIMultipleUpdateResults(success, error, responses));

        this.socket.on("release", (success: boolean, message: string) => this.stopLoadingAndShowMessage(success, message));
        this.socket.on("deploy", (success: boolean, message: string) => this.stopLoadingAndShowMessage(success, message));
        this.socket.on("is-deployed", (game: string, deployed: boolean, hasEverDeployed: boolean, stopLoad: boolean) => this.updateDeploymentStatus(game, deployed, hasEverDeployed, stopLoad))

        this.socket.on("branchversion", (game: string, branch: string, version: string, match?: boolean) => this.updateVersionStatus(game, branch, version, match));
        this.socket.on("rtpvalues", (gameid: string, rtp: string[]) => this.setRTPValues(gameid, rtp));

        this.socket.on("validate", (success: boolean) => this.validateLogin(success));

        this.socket.on("approve-response", (success: boolean) => this.handleApprovalResponse(success));
        this.socket.on("is-approved", (game: string, branch: string, approved: boolean) => this.updateApprovalStatus(game, approved));

        this.socket.on("lib-versions", (game: string, libs?: { repo: string, libs: { [key: string]: string } }) => this.updateLibs(game, libs));

        this.socket.on("generate-short-url", (short: string) => this.copyShortURL(short));
        this.socket.on("belarus-hash", (game: string, result: string | null) => this.handleBelarusHashResponse(game, result));
    
        this.socket.on("connected", (id: string, user: GoogleProfile | undefined, permissions: PermissionLevels) => {
            if (this.socket.id === id) {
                if (user && user.emails && user.emails.length > 0) {
                    this.setState({ loginEmail: user.emails[0].value, loginUsername: user.displayName, permissions }, () => {
                        this.socket.emit("validate", this.state.loginEmail);
                    });
                } else {
                    console.log("clearing after connected");
                    this.clearLogin();
                }
            }
        });
    }
}
