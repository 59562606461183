interface DeploymentLink {
    id: string;
    deployment: string;
}

const DeploymentLinks: DeploymentLink[] = [
    {
        id: "100k-joker",
        deployment: "100k-joker-slots"
    },
    {
        id: "cheeky-fruits-deluxe-slots",
        deployment: "cheeky-fruits-deluxe"
    },
    {
        id: "cheeky-fruits-5",
        deployment: "cheeky-fruits"
    },
    {
        id: "elite-of-evil",
        deployment: "elite-of-evil-slots"
    },
    {
        id: "elite-of-evil",
        deployment: "elite-of-evil-slots"
    },
    {
        id: "good-bad-ugly-slots",
        deployment: "good-bad-ugly"
    },
    {
        id: "retro-solitaire-slots",
        deployment: "retro-solitaire"
    },
    {
        id: "leprechauns-lucky-emporium-slots",
        deployment: "luck-emporium-slots"
    },
    {
        id: "branded-solitaire-slots",
        deployment: "retro-solitaire-br"
    },
    {
        id: "diamond-digger-slots",
        deployment: "diamond-digger"
    },
    {
        id: "postcode-patience-slots",
        deployment: "patience-slots"
    }
];

export function getDeploymentPath(id: string): string {
    const link = DeploymentLinks.find(x => x.id === id);
    if (link) {
        return link.deployment;
    }
    return id;
}

const RepoLinks: DeploymentLink[] = [
    {
        id: "elite-of-evil",
        deployment: "elite-of-evil-slots"
    },
    {
        id: "vegans-vs-vampires",
        deployment: "vegans-vs-vampires"
    }
];

export function getRepo(id: string): string {
    const link = RepoLinks.find(x => x.id === id);
    if (link) {
        return link.deployment;
    }
    return id;
}