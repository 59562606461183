import React from "react";
import { Button, Dimmer, Form, Grid, Header, Loader, Segment } from "semantic-ui-react";
import { Options } from "../data/options";
import { IsMobile } from "../utils/contextual-css";
import { getCorsAnywhereURL } from "../utils/cors-anywhere";
import { TabComponent } from "./tab-component";

interface IProperties {
    gameID?: string;
    username?: string;
    showMessage: (message: string, error: boolean) => void;
}

interface IState {
    currency: string;
    maxSpinValue: number;
    totalSpins: number;

    loading: boolean;
}

export class FreeRounds extends TabComponent<IProperties, IState> {
    constructor(props: IProperties) {
        super(props, "FreeRounds");

        this.state = {
            maxSpinValue: 1,
            totalSpins: 10,
            currency: Options["Currency"].options![0],

            loading: false
        };
    }

    public render(): JSX.Element {
        if (!this.props.gameID) {
            return (
                <Segment textAlign="center">
                    <Header as="h5" textAlign="center" content="Free Rounds" />
                    <p>
                        Failed to get the ID for the current game. Please make sure the gameID is set in app.ts on the master / main branch.<br/>
                        If you have done this recently, please wait 5 minutes for the portal to update.
                    </p>
                </Segment>
            )
        } else if (!this.props.username) {
            return (
                <Segment textAlign="center">
                    <Header as="h5" textAlign="center" content="Free Rounds" />
                    <p>
                        Free Rounds are only available for real users. Please input a user before using this tool.
                    </p>
                </Segment>
            )
        } else {
            return (
                <Segment>
                    <Header as="h5" textAlign="center" content="Free Rounds" />
                    <Form>
                        <Grid columns={(IsMobile() ? 1 : 3)} textAlign="center" centered stretched>
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Input
                                        label="Max Spin Value"
                                        type="number"
                                        value={this.state.maxSpinValue}
                                        onChange={(e, d) => this.setState({ maxSpinValue: Number(d.value) })}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Input
                                        label="Total Spins"
                                        type="number"
                                        value={this.state.totalSpins}
                                        onChange={(e, d) => this.setState({ totalSpins: Number(d.value) })}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Select
                                        label="Currency"
                                        options={Options["Currency"].options!.map((x) => { return { text: x, value: x } })}
                                        value={this.state.currency}
                                        onChange={(e, d) => this.setState({ currency: d.value as string }) }
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width="16">
                                    <Button fluid content="Add Free Rounds" onClick={() => this.addFreeRounds()} loading={this.state.loading} disabled={this.state.loading} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Segment>
            )
        }
    }

    private addFreeRounds(): void {
        this.setState({ loading: true });

        const data = {
            "gameId": this.props.gameID,
            "playerId": this.props.username,
            "currency": this.state.currency,
            "maxSpinValue": this.state.maxSpinValue,
            "totalSpins": this.state.totalSpins
        };

        const request: XMLHttpRequest = new XMLHttpRequest();
        request.onload = () => {
            this.setState({ loading: false });
            if (request.status === 200) {
                this.props.showMessage(`Free Rounds were added successfully for ${this.props.gameID}`, false);
            } else {
                this.props.showMessage(`${request.status}\n${request.statusText}`, true);
            }
        };
        const link: string = "https://bornlucky-staging-euwest1.gamevy.com/gamevy/free-spins";
        request.open("POST", getCorsAnywhereURL(link));
        request.setRequestHeader("Content-Type", "application/json");
        request.send(JSON.stringify(data));
    }
}