import React from "react";
import { Header, Segment, Button } from "semantic-ui-react";
import { getCorsAnywhereURL } from "../utils/cors-anywhere";
import { downloadFile } from "../utils/download-file";
import { TabComponent } from "./tab-component";
import { getFileURLPath } from "../data/files-links";

interface IProperties {
    available?: string;

    game?: string;
    client: string;

    operator?: string;
    currency?: string;
    jurisdiction?: string;

    requestBelarus: (game: string) => void;
}

interface IState {

}

export class GameFiles extends TabComponent<IProperties, IState> {
    constructor(props: IProperties) {
        super(props, "GameFiles");

        this.state = {

        };
    }

    public render(): JSX.Element {
        if ((this.props.client === "Epic" || this.props.client === "Gamevy") && this.props.available !== undefined && this.props.game !== undefined) {
            return (
                <div>
                    <Segment textAlign="center">
                        <Header as="h5" textAlign="center" content="Game Files" />
                        <Button.Group fluid>
                            <Button
                                content="Hash Data"
                                onClick={(ev) => {
                                    if (ev.shiftKey) {
                                        this.downloadBelarusHashData();
                                    } else {
                                        this.downloadHashData();
                                    }
                                }}
                            />
                            <Button
                                content="Zip"
                                onClick={() => this.downloadZipFile()}
                            />
                            {/* <Button
                                content="History"
                                onClick={() => this.downloadBelarusHashData()}
                            /> */}
                        </Button.Group>
                    </Segment>
                </div>
            );
        } else {
            return <span></span>;
        }
    }

    protected downloadHashData(): void {
        const game = getFileURLPath(this.props.game?.replace("-slots", "")!);
        downloadFile(
            getCorsAnywhereURL(`https://bornlucky-staging-euwest1.gamevy.com/${game}/critical-files?${this.getParams()}`),
            `${game}-hash.json`,
            { key: "x-gamevy-session-token", value: "guest" }
        ).then((success) => {
            
        });;
    }

    protected downloadZipFile(): void {
        const game = getFileURLPath(this.props.game?.replace("-slots", "")!);
        downloadFile(
            getCorsAnywhereURL(`https://bornlucky-staging-euwest1.gamevy.com/${game}/critical-files-zip?${this.getParams()}`),
            `${game}-files.zip`,
            { key: "x-gamevy-session-token", value: "guest" }
        ).then((success) => {
            if (!success) {
                // Fallback critical files collection.

            }
        });
    }

    protected downloadBelarusHashData(): void {
        const game = getFileURLPath(this.props.game?.replace("-slots", "")!);
        this.props.requestBelarus(game);
    }

    protected getParams(): string {
        return `platform=gamevy&currency=${this.props.currency ?? ""}&operator=${this.props.operator ?? ""}&jurisdiction=${this.props.jurisdiction ?? ""}`;
    }
}