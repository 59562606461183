import React from "react";
import ReactDOM from "react-dom";

import "semantic-ui-css/semantic.min.css";
import "./style.scss";

import { Application } from "src/frontend/application";
import { SetupContextualCSS } from "./utils/contextual-css";

SetupContextualCSS();

const app = document.createElement("div");
app.id = "app";
document.body.appendChild(app);

const socketScript = document.createElement("script");
socketScript.src = "/socket.io/socket.io.js";
document.head.appendChild(socketScript);

ReactDOM.render(<Application />, app);
