import React from "react";
import { Button, Form, Segment } from "semantic-ui-react";

interface IProperties {
    value: string;
    history: string[];

    onChange: (value: string) => void;
}

interface IState {

}

export class Login extends React.Component<IProperties, IState> {
    constructor(props: IProperties) {
        super(props);

        this.state = {
            
        };
    }

    public render(): JSX.Element {
        return (
            <Segment>
                <div>
                    <Form.Input
                        id="login-input"
                        value={this.props.value}
                        label="Username"
                        fluid
                        placeholder="Fun mode"
                        onChange={(e, d) => {
                            this.setState(this.state, () => {
                                this.props.onChange(d.value as string);
                            });
                        }}
                        list="history"
                        action={<Button content="Random" onClick={() => {
                            let result = '';
                            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                            const charactersLength = characters.length;
                            for ( let i = 0; i < 10; i++ ) {
                                result += characters.charAt(Math.floor(Math.random() * charactersLength));
                            }
                            const userInput = document.getElementById("login-input") as HTMLInputElement;
                            userInput.value = result;
                            this.setState(this.state, () => {
                                this.props.onChange(result);
                            });
                        }} />}
                    >
                    </Form.Input>
                    <datalist id="history">{ this.props.history.map(x => <option key={x} value={x}>{x}</option>) }</datalist>
                </div>
            </Segment>
        );
    }
}
