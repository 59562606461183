import React from "react";
import { Header, Segment, Button } from "semantic-ui-react";
import { TabComponent } from "./tab-component";

interface IProperties {
    available?: string;

    approved?: boolean;

    game?: string;
    branch?: string;

    onApprove: (approve: boolean, game: string, branch: string) => void;
}

interface IState {

}

export class QAApproval extends TabComponent<IProperties, IState> {
    constructor(props: IProperties) {
        super(props, "QAApproval");

        this.state = {

        };
    }

    public render(): JSX.Element {
        if (this.props.game !== undefined && (this.props.branch == "master" || this.props.branch == "main")) {
            return (
                <div>
                    <Segment textAlign="center">
                        <Header as="h5" textAlign="center" content="QA Approval" />
                        { this.showStatus() }
                        { this.addButtons() }
                    </Segment>
                </div>
            );
        } else {
            return <span></span>;
        }
    }

    protected showStatus(): JSX.Element {
        if (this.props.approved !== undefined) {
            if (this.props.approved) {
                return <p style={{ color: "green" }}>The latest master build is approved by QA!</p>
            } else {
                return <p style={{ color: "red" }}>The latest master build has not yet been approved by QA! Please check it first for changes.</p>
            }
        } else {
            return <p>Checking...</p>
        }
    }

    protected addButtons(): JSX.Element {
        if (this.props.available !== undefined && this.props.approved !== undefined) {
            if (this.props.approved) {
                return (
                    <Button.Group fluid>
                        <Button
                            negative
                            basic
                            content="Unapprove"
                            onClick={() => this.props.onApprove(false, this.props.game!, this.props.branch!)}
                        />
                    </Button.Group>
                )
            }
            return (
                <Button.Group fluid>
                    <Button
                        positive
                        basic
                        content="Approve"
                        onClick={() => this.props.onApprove(true, this.props.game!, this.props.branch!)}
                    />
                </Button.Group>
            );
        } else {
            return <span></span>;
        }
    }
}