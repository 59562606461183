import { getCorsAnywhereURL } from "./cors-anywhere";

export function GetCommitHash(link: string): Promise<string> {
    return new Promise((resolve) => {
        const request = new XMLHttpRequest();
        request.open("GET", getCorsAnywhereURL(link));
        request.onload = () => {
            const response = request.responseText;
            const path = response.match(/"\/d\/([^\/]+)\/index\.html"/);
            if (path && path.length > 1) {
                resolve(path[1]);
            } else {
                resolve("");
            }
        };
        request.send();
    });
}