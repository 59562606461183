interface FileURLLink {
    id: string;
    link: string;
}

const FileURLLinks: FileURLLink[] = [
    {
        id: "1000000-book",
        link: "million-book"
    },
    {
        id: "clover-the-rainbow-6",
        link: "clover-the-rainbow-deluxe"
    },
    {
        id: "psycho-candy",
        link: "psycho-candy-slots"
    },
    {
        id: "eastern-gold-6",
        link: "eastern-gold-deluxe"
    },
    {
        id: "cheeky-fruits-5",
        link: "cheeky-fruits"
    },
    {
        id: "book-of-the-king",
        link: "tomb-of-the-king"
    },
    {
        id: "elite-of-evil",
        link: "elite-of-evil-slots"
    }
];

export function getFileURLPath(id: string): string {
    const link = FileURLLinks.find(x => x.id === id);
    if (link) {
        return link.link;
    }
    return id;
}