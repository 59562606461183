import React from "react";
import { Button, Dimmer, Form, Header, Loader, Message, Segment, Modal } from "semantic-ui-react";
import { copyToClipboard } from "../utils/copy-to-clipboard";
import { OpenLink } from "../utils/open-link";
import { TabComponent } from "./tab-component";

interface IProperties {
    available?: string;

    game: string;

    releasePending: boolean;
    checkingRelease: boolean;

    onReleaseConfirm: (game: string, password: string) => void;
}

interface IState {
    modalOpen: boolean;
}

export class Release extends TabComponent<IProperties, IState> {
    constructor(props: IProperties) {
        super(props, "Release");

        this.state = {
            modalOpen: false
        };
    }

    public render(): JSX.Element {
        if (this.props.available !== undefined) {
            return (
                <Segment textAlign="center">
                    <Header as="h5" textAlign="center" content="Release" />
                    { this.getMessage() }
                </Segment>
            );
        } else {
            return <span></span>;
        }
    }

    private renderForm(): JSX.Element[] {
        return [
            <p key="release-message">A release is pending for this game. To automatically merge the latest develop into master, enter your password and press the "Release" button.</p>,
            <Form key="release-form">
                <Form.Button
                    content="Release"
                    primary
                    onClick={() => {
                        this.setState({ modalOpen: true });
                    }}
                />
                <Modal open={this.state.modalOpen}>
                    <Modal.Header>Release Game</Modal.Header>
                    <Modal.Content>
                        Are you sure you want to merge develop into master for {this.props.game}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button content="Yes" color="green" onClick={() => {
                            this.setState({ modalOpen: false }, () => {
                                this.props.onReleaseConfirm(this.props.game, this.props.available!);
                            });
                        }} />
                        <Button content="No" color="red" onClick={() => {
                            this.setState({ modalOpen: false });
                        }} />
                    </Modal.Actions>
                </Modal>
            </Form>
        ];
    }

    private getMessage(): JSX.Element | JSX.Element[] {
        if (this.props.checkingRelease) {
            return <p>Checking...</p>;
        } else {
            return (this.props.releasePending) ? this.renderForm() : <p>No release pending for this game.</p>;
        }
    }
}