import React from "react";
import { Segment, Table } from "semantic-ui-react";
import { TabComponent } from "./tab-component";

interface IProperties {
    game: string;
    libs?: { repo: string, libs: { [key: string]: string } };
    loading: boolean;
}

interface IState {

}

export class LibVersions extends TabComponent<IProperties, IState> {
    constructor(props: IProperties) {
        super(props, "LibVersions");

        this.state = {
            
        };
    }

    public render(): JSX.Element {
        if (this.props.loading) {
            return (
                <Segment textAlign="center">
                    Loading lib data... please wait...
                </Segment>
            )
        } else if (this.props.libs) {
            const rows: JSX.Element[] = [];
            for (const key in this.props.libs.libs) {
                rows.push(
                    <Table.Body>
                        <Table.Cell>
                            { key }
                        </Table.Cell>
                        <Table.Cell>
                            { this.props.libs.libs[key] }
                        </Table.Cell>
                    </Table.Body>
                );
            }
            return (
                <Segment textAlign="center">
                    <Table celled definition>
                        { rows }
                    </Table>
                    <p><i>Please note these values are estimations and may not be 100% accurate if they have changed recently, or only on one branch.</i></p>
                </Segment>
            )
        } else {
            return (
                <Segment textAlign="center">
                    The lib versions are not available for this game yet.<br/>
                    They will be ready after the next build.
                </Segment>
            )
        }
    }
}