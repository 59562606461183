import React from "react";

export class TabComponent<P, S> extends React.Component<P, S> {
    protected static initialisedStorage: string[] = [];

    protected id: string;
    protected previousState?: S;

    constructor(props: P, id: string) {
        super(props);

        this.id = id;

        if (TabComponent.initialisedStorage.indexOf(id) === -1) {
            TabComponent.initialisedStorage.push(id);
            localStorage.setItem(id, "{}");
        }
    }

    public componentDidMount() {
        this.previousState = JSON.parse(localStorage.getItem(this.id) || "{}");
        if (Object.keys(this.previousState!).length > 0) {
            this.setState({ ...this.previousState }, () => this.onStateRestored());
        }
    }

    public componentWillUnmount() {
        localStorage.setItem(this.id, JSON.stringify(this.state));
    }

    public componentDidUpdate() {
        localStorage.setItem(this.id, JSON.stringify(this.state));
    }

    protected onStateRestored() {
        // Do nothing.
    }
}
