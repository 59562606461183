import { getCorsAnywhereURL } from "./cors-anywhere";

export function getSessionID(username: string, operator: string, platform: string, gameId: string = "VOMITING_UNICORNS"): Promise<string> {
    const url: string = "https://bornlucky-staging-euwest1.gamevy.com/gamevy/launch-game";
    const params: string = `?gameId=${gameId}&mode=real&currency=GBP&lang=en-GB&operator=${operator}&platform=${platform}`;

    return new Promise((resolve, reject) => {
        const request: XMLHttpRequest = new XMLHttpRequest();
        request.onload = () => {
            const url: string | null = request.getResponseHeader("x-final-url");
            if (url) {
                const regex: RegExpMatchArray | null = url.match(/sid=([\w\d-]+)/);
                if (regex && regex.length > 1) {
                    resolve(regex[1]);
                    return;
                }
            }
            reject();
        }
        const gameURL: string = url + params + `&playerId=${username}`;
        const targetURL: string = getCorsAnywhereURL(gameURL);
        request.open("GET", targetURL);
        request.send();
    });
}