import React from "react";
import { Button, Header, Message, Segment } from "semantic-ui-react";
import { GameType } from "../application";
import { copyToClipboard } from "../utils/copy-to-clipboard";
import { OpenLink } from "../utils/open-link";
import { TabComponent } from "./tab-component";

interface IProperties {
    gameID?: string;
    deployment?: string;
    game: string;
    client: string;
    gameType: GameType;
    defaultBranch?: string;
    showMessage: (message: string, error: boolean) => void;
    generateParameters: () => string;
}

interface IState {

}

export class AdditionalLinks extends TabComponent<IProperties, IState> {
    constructor(props: IProperties) {
        super(props, "AdditionalLinks");

        this.state = {
            
        };
    }

    public render(): JSX.Element {
        if (this.props.gameID && this.props.deployment) {
            return (
                <Segment>
                    {
                        (this.props.gameType === GameType.Slotworks) ?
                        <Button.Group fluid widths="8">
                            <Button content="Copy Test Launcher" onClick={() => this.copyLauncherLink("test")} />
                            <Button content="Copy Staging Launcher" onClick={() => this.copyLauncherLink("staging")} />
                            <Button content="Copy Production Launcher" onClick={() => this.copyLauncherLink("prod")} />
                        </Button.Group> : undefined
                    }
                    <Button.Group fluid widths="8" style={{ marginTop: "5pt" }}>
                        <Button content="View Reels" onClick={() => {
                            this.setState({ loading: true });
                            this.setState({ loading: false });
                            const url: string = `https://github.com/gluckgames/slots-service/tree/master/service/config/${this.props.gameID}/reels`;
                            OpenLink(url);
                        }} />
                        <Button content="View Paytable" onClick={() => {
                            this.setState({ loading: true });
                            this.setState({ loading: false });
                            const url: string = `https://github.com/gluckgames/slots-service/tree/master/service/config/${this.props.gameID}/paytables`;
                            OpenLink(url);
                        }} />
                    </Button.Group>
                    <Button.Group fluid widths="16" style={{ marginTop: "5pt" }}>
                        <Button content="Testing Links" onClick={() => {
                            const defaultBranch = this.props.defaultBranch ? `&branch=${this.props.defaultBranch}` : "";
                            OpenLink(`/testing/?game=${this.props.deployment}&gameType=${this.props.gameType}${defaultBranch}`);
                        }} />
                    </Button.Group>
                </Segment>
            )
        } else {
            return (
                <Segment textAlign="center">
                    Failed to get the ID for the current game. Please make sure the gameID is set in app.ts on the main or master branch.<br/>
                    If you have done this recently, please wait 5 minutes for the portal to update.
                </Segment>
            )
        }
    }

    private async copyLauncherLink(type: "test" | "staging" | "prod"): Promise<void> {
        this.setState({ loading: true });
        const url: string | null = await this.generateLauncherLink(type);
        this.setState({ loading: false });
        if (url) {
            copyToClipboard(url);
            this.props.showMessage("Copied Launcher URL: " + type, false);
        } else {
            this.props.showMessage("Failed to get Game ID", true);
        }
    }

    private async generateLauncherLink(type: "test" | "staging" | "prod"): Promise<string | null> {
        return `https://bornlucky-${type}-euwest1.gamevy.com/gamevy/launch-game?${this.props.generateParameters()}&gameId=${this.props.gameID}`;
    }
}